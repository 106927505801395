// import { Loader } from '@/src/shared/ui-kit/components/loader';
import {
  ChangeEvent,
  ChangeEventHandler,
  ForwardRefExoticComponent,
  KeyboardEventHandler,
  RefAttributes,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import TextareaAutosize, {
  TextareaAutosizeProps,
} from 'react-textarea-autosize';
import { Celebrations, OccasionTopics } from 'charlie-workflows/types';
import Image from 'next/image';

import styles from './message-input.module.scss';
import { Placeholder } from '../placeholder/placeholder.component';
import { MessageCapsules } from '../message-capsules';
import { FiShare } from 'react-icons/fi';
import { HiPaperClip, HiX } from 'react-icons/hi';
import { SendlIcon } from '../../icons/send.icon.component';
import { IProgressBarRef, ProgressBar } from '../progress-bar';
import { CAPSULE_SEPARATOR } from '../chat/constants/chat.constants';
import { LottiePlayer } from '../lottie-player/lottie-player.component';

import { useInjectable } from '@/src/services';
import {
  FeatureFlagsServiceContainerType,
  IFeatureFlagsService,
  SupportedFlags,
} from '@/src/services/feature-flags';

const TextareaAutosizeComponent: ForwardRefExoticComponent<
  TextareaAutosizeProps &
    RefAttributes<HTMLTextAreaElement> & { enterKeyHint: 'send' }
> = TextareaAutosize;

export interface IMessageInputRef {
  focus: () => void;
}

interface IMessageInputProps {
  rows?: number;
  className?: string;
  showSkeleton?: boolean;
  value: string;
  chatState?: string | null;
  placeholder?: string | string[];
  suggestions?: string[];
  autofocus?: boolean;
  mode?: Celebrations | OccasionTopics | 'news' | null;
  onSend: (value: string, imageBuffer?: File) => void;
  onSuggest?: (value: string) => void;
  onShare?: () => void;
  onSendAudio?: (blob: Blob) => void;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;

  imageAttachingEnabled?: boolean;
}

export const MessageInput = forwardRef<IMessageInputRef, IMessageInputProps>(
  function MessageInput(
    {
      className,
      showSkeleton,
      value,
      chatState = null,
      placeholder,
      autofocus = true,
      suggestions,
      onChange,
      onSend,
      onShare,
      onSuggest,
      imageAttachingEnabled = true,
    },
    ref,
  ) {
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const progressBarRef = useRef<IProgressBarRef>(null);
    const imageInputRef = useRef<HTMLInputElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const fileRef = useRef<File | undefined>(undefined);
    const [progressBarVisible, setProgressBarVisible] = useState(false);
    const [selectedPicture, setSelectedPicture] = useState<
      string | ArrayBuffer | null
    >(null);
    const loading = Boolean(chatState);

    const { getFlagValueForFeature } = useInjectable<IFeatureFlagsService>(
      FeatureFlagsServiceContainerType,
    );

    const imageAttachingFeatureFlagEnabled = getFlagValueForFeature(
      SupportedFlags.ImageUpload,
    );

    useImperativeHandle(ref, () => {
      return {
        focus: () => {
          textareaRef.current?.focus();
        },
      };
    });

    useEffect(() => {
      if (chatState && chatState.trim().length) {
        if (!progressBarVisible) {
          setProgressBarVisible(true);
        }

        progressBarRef.current?.next();
      }
    }, [chatState, progressBarVisible, progressBarRef]);

    useEffect(() => {
      function handleClickOutside(event: TouchEvent) {
        if (
          containerRef.current &&
          textareaRef.current &&
          !containerRef.current.contains(event.target as Node)
        ) {
          textareaRef.current.blur();
        }
      }

      document.addEventListener('touchstart', handleClickOutside);

      return () => {
        document.removeEventListener('touchstart', handleClickOutside);
      };
    }, [textareaRef, containerRef]);

    // useEffect(() => {
    //   textareaRef.current?.setAttribute('enterKeyHint', 'send');
    // }, [textareaRef]);

    useEffect(() => {
      if (autofocus && !Boolean(chatState)) {
        textareaRef.current?.focus();
      }
    }, [autofocus, chatState, textareaRef]);

    const handleKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = (
      event,
    ) => {
      if (
        event.key.toLowerCase() === 'enter' &&
        !event.shiftKey &&
        !event.ctrlKey
      ) {
        event.preventDefault();

        if (!(loading || !value)) {
          handleSubmit();
        }
      }
    };

    const handlePictureSelect: ChangeEventHandler<HTMLInputElement> = (
      event,
    ) => {
      const file = event.target.files?.[0];
      fileRef.current = file;
      const reader = new FileReader();

      reader.onloadend = () => {
        setSelectedPicture(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    };

    const handleOpenImageSelect = () => {
      imageInputRef.current?.click();
    };

    const handleSubmit = () => {
      onSend(value, fileRef.current);
      setSelectedPicture(null);
      fileRef.current = undefined;
      // textareaRef.current?.focus();
    };

    const placeholderVisible =
      placeholder && Array.isArray(placeholder) && !Boolean(value);

    return (
      <div className={`${styles.form} ${className || ''}`} ref={containerRef}>
        <div className={styles.textAreaWrapper}>
          {!loading ? (
            <TextareaAutosizeComponent
              ref={textareaRef}
              className={`${styles.messageInput} ${
                placeholderVisible ? styles.withPlaceholderInput : ''
              }`}
              spellCheck="false"
              value={value}
              rows={1}
              enterKeyHint="send"
              autoFocus={autofocus}
              disabled={loading}
              placeholder={
                loading
                  ? ''
                  : Array.isArray(placeholder)
                  ? undefined
                  : placeholder
              }
              onKeyDown={handleKeyDown}
              onChange={(event) => {
                onChange(event);
              }}
              aria-label="Type your message here"
            />
          ) : null}

          {placeholderVisible ? (
            <div className={styles.placeholderWrapper}>
              <Placeholder
                placeholders={placeholder as string[]}
                onClick={() => textareaRef?.current?.focus()}
              />
            </div>
          ) : null}
          {loading ? (
            <div className={styles.thinkingWrapper}>
              <div className={styles.playerContainer}>
                <LottiePlayer
                  autoplay
                  loop
                  src="thinking-animation.json"
                  // style={{ height: '300px', width: '300px' }}
                />
              </div>

              <span>{chatState}</span>
            </div>
          ) : null}
          <div className={styles.actions}>
            {imageAttachingEnabled && imageAttachingFeatureFlagEnabled ? (
              <>
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  ref={imageInputRef}
                  onChange={handlePictureSelect}
                />

                <button
                  disabled={loading}
                  className={styles.attachImageButton}
                  aria-label="Attach an image"
                  title="Attach an image"
                  onClick={handleOpenImageSelect}
                >
                  {loading ? null : <HiPaperClip />}
                </button>
              </>
            ) : null}

            {onShare ? (
              <button
                disabled={loading}
                className={styles.shareButton}
                aria-label="Share"
                title="Share"
                onClick={onShare}
              >
                {loading ? null : <FiShare />}
              </button>
            ) : null}

            <button
              disabled={loading || !value}
              className={styles.messageSendButton}
              aria-label="Send"
              title="Send"
              onClick={handleSubmit}
            >
              {loading ? null : <SendlIcon />}
            </button>
          </div>
        </div>
        {loading && (showSkeleton || suggestions?.length) ? (
          <MessageCapsules className={styles.skeletonCapsules} skeleton={3} />
        ) : null}
        {!loading && suggestions?.length ? (
          <div className={styles.capsulesContainer} id="suggestions-capsules">
            <MessageCapsules
              className={styles.capsules}
              active={true}
              marked={value.split(CAPSULE_SEPARATOR)}
              selection={suggestions || []}
              onSuggest={(content) => {
                onSuggest?.(content);
                textareaRef?.current?.focus();
              }}
            />
          </div>
        ) : null}

        {selectedPicture && (
          <div className={styles.attachedImageWrapper}>
            <Image
              className={styles.attachedImage}
              width={80}
              height={80}
              src={selectedPicture as string}
              alt="Selected"
            />

            <button
              className={styles.deleteAttachedImageButton}
              onClick={() => {
                setSelectedPicture(null);
                fileRef.current = undefined;
              }}
            >
              <HiX />
            </button>
          </div>
        )}

        {progressBarVisible ? (
          <div className={styles.progressBarWrapper}>
            <ProgressBar
              ref={progressBarRef}
              loading={loading}
              onAnimatedToTheEnd={() => {
                setProgressBarVisible(false);
              }}
            />
          </div>
        ) : null}

        {/* {!loading && onSendAudio ? (
          <AudioRecorder
            onRecordingComplete={onSendAudio}
            audioTrackConstraints={{
              noiseSuppression: true,
              echoCancellation: true,
            }}
            downloadOnSavePress={false}
          />
        ) : null} */}
      </div>
    );
  },
);
